import { BlacklistToken, GenerateNewTokens } from 'app/redux/actions/auth';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import instance from '../redux/instance';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // check if token is still valid.
  useEffect(() => {
    const refreshToken = Cookies.get('refreshToken');
    const accessToken = Cookies.get('accessToken');

    async function generateNewToken() {
      const newTokens = await dispatch(GenerateNewTokens(refreshToken));

      const newAccessToken = newTokens.access;
      const newRefreshToken = newTokens.refresh;

      const { exp: newAccessTokenExp } = jwtDecode(newAccessToken);
      const { exp: newRefreshTokenExp } = jwtDecode(newRefreshToken);

      Cookies.set('accessToken', newTokens.access, {
        expires: new Date(newAccessTokenExp * 1000),
      });
      Cookies.set('refreshToken', newTokens.refresh, {
        expires: new Date(newRefreshTokenExp * 1000),
      });

      instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      const { user_id, email } = jwtDecode(newTokens.access);

      setUser({
        userID: user_id,
        email,
      });
      setLoading(false);
    }

    if (refreshToken) {
      const currentTime = Date.now() / 1000;
      const { exp: refreshTokenExp } = jwtDecode(refreshToken);

      if (refreshTokenExp < currentTime) logout();
      else if (!accessToken || (accessToken && accessToken.exp < currentTime))
        generateNewToken();
      else {
        instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        const { user_id, email } = jwtDecode(accessToken);
        setUser({
          userID: user_id,
          email,
        });
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // call this function to log in user.
  const login = ({ accessToken, refreshToken }) => {
    const { exp: accessTokenExp } = jwtDecode(accessToken);
    const { exp: refreshTokenExp } = jwtDecode(refreshToken);

    Cookies.set('accessToken', accessToken, {
      expires: new Date(accessTokenExp * 1000),
    });
    Cookies.set('refreshToken', refreshToken, {
      expires: new Date(refreshTokenExp * 1000),
    });

    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { user_id, email } = jwtDecode(accessToken);

    setUser({
      userID: user_id,
      email,
    });
    setLoading(false);
    // TO DO: remove later and simply redirect to /
    navigate('/', { replace: true });
  };

  // call this function to sign out logged in user.
  const logout = async () => {
    const refreshToken = Cookies.get('refreshToken');
    await dispatch(BlacklistToken(refreshToken));

    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');

    delete instance.defaults.headers.common.Authorization;

    setUser(null);

    navigate('/', { replace: true });
  };

  const value = useMemo(
    () => ({
      isAuthenticated: !!user,
      user,
      loading,
      login,
      logout,
      setUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
